<template>
  <f7-page
    name="home"
    :page-content="false"
  >
    <f7-navbar
      no-hairline
      no-shadow
      style="display: none;"
    >
      <div class="right">
        <a class="link icon-only">
          <i class="icon icon-move-vert"></i>
        </a>
      </div>
    </f7-navbar>
    <f7-page-content class="hide-navbar-on-scroll">
      <f7-block class="main-block no-margin-vertical">
          <div class="first-row">
            <div class="logo-wrap">
              <img src="static/icons/casino-logo.png" alt="" width="66" srcset="">
            </div>
            <div class="title-wrap">
              <span class="title">Olymp Casino</span>
            </div>
          </div>
          <div class="second-row">
            <table class="table">
              <tr>
                <td valign="center" class="star-cell">
                  <div class="flex align-items">
                    <span class="text">4,8</span>
                    <i class="icon icon-mini-star"></i>
                  </div>
                </td>
                <td valign="center">
                  <i class="icon icon-down-arrow"></i>
                </td>
                <td valign="center" class="downloads-cell">
                  <span class="text">Более 10 000</span>
                </td>
              </tr>
              <tr>
                <td valign="top" class="description-cell">
                  <p class="text">84 отзыва</p>
                </td>
                <td valign="top" class="description-cell">
                  <p class="text">13,2 МБ</p>
                </td>
                <td valign="top" class="description-cell">
                  <p class="text">Количество скачиваний</p>
                </td>
              </tr>
            </table>
          </div>
          <div class="third-row">
            <a :href="this.$root.android_link" class="link external submit">Установить</a>
          </div>
          <f7-block class="fourth-row">
            <span style="color: white">Старые версии</span>
            <f7-link :href="this.$root.android_mirror" class="link external submit" style="color: #00875F">Зеркало</f7-link>
          </f7-block>
      </f7-block>
      <div id="container-screens">
        <div id="screens">
            <div class="screen">
              <img src="static/screensMini/i1.webp" data-id="1" @click="openImage" width="92" height="180" alt="" srcset="">
            </div>
            <div class="screen">
              <img src="static/screensMini/i2.webp" data-id="2" width="92" @click="openImage" height="180" alt="" srcset="">
            </div>
            <div class="screen">
              <img src="static/screensMini/i3.webp" data-id="3" width="92" @click="openImage" height="180" alt="" srcset="">
            </div>
            <div class="screen">
              <img src="static/screensMini/i4.webp" data-id="3" width="92" @click="openImage" height="180" alt="" srcset="">
            </div>
        </div>
      </div>
      <f7-block class="description">
        <div class="title-row">
          <div class="wrap-title">
            <h5 class="no-margin-vertical">Описание</h5>
          </div>
          <i class="icon icon-arrow-right"></i>
        </div>
        <div class="no-margin-vertical">
          Добро пожаловать в Olymp Casino – приложение, где азарт встречается с уникальным игровым опытом. Насладитесь захватывающими играми, бонусами и возможностью выиграть крупные призы, не выходя из дома.
          <a href="#" @click="showMore" style="font-size: 15px;color: #007AFF;">...еще</a>
          <div id="info-more" style="display: none;">
            <br>
            <b>Разнообразие</b> классических слотов до захватывающих настольных игр - у нас есть все, чтобы удовлетворить ваши азартные потребности.<br><br>
            <b>Эксклюзивные Бонусы</b>: Получайте уникальные бонусы и приветственные предложения<br><br>
            <b>Турниры и Соревнования</b>: Участвуйте в еженедельных турнирах и соревнованиях с другими игроками, чтобы выиграть крупные призы и подняться в рейтинге.<br><br>
            <b>Безопасность и Надежность</b>: Olymp Casino обеспечивает высокий уровень безопасности и конфиденциальности ваших данных, чтобы вы могли наслаждаться игрой с полным спокойствием.<br><br>
            <b>Легкость Использования</b>: Интуитивно понятный интерфейс делает использование приложения Olymp Casino легким и удобным.<br><br>
            Станьте частью азартного мира Olymp Casino!
          </div>
        </div>
      </f7-block>
      <f7-block class="keywords">
        <span>Спорт</span>
        <span>Бестселлеры, №1</span>
      </f7-block>
      <f7-block class="no-margin-vertical reviews">
        <div class="title-row">
          <div class="wrap-title">
            <h5 class="no-margin-vertical">Оценки и отзывы</h5><i class="icon icon-info"></i>
          </div>
          <i class="icon icon-arrow-right"></i>
        </div>
        <div class="contaiter">
          <div class="raiting">
            <div class="wrap-box">
              <h5 class="no-margin-top">4,8</h5>
              <i class="icon icon-group-stars"></i>
              <div class="people">10 000</div>
            </div>
          </div>
          <div class="details">
            <div class="wrap-lines">
              <div class="line"><span>5</span><div class="progress-bar"><div class="value-1"></div></div></div>
              <div class="line"><span>4</span><div class="progress-bar"><div class="value-2"></div></div></div>
              <div class="line"><span>3</span><div class="progress-bar"><div class="value-3"></div></div></div>
              <div class="line"><span>2</span><div class="progress-bar"><div class="value-3"></div></div></div>
              <div class="line"><span>1</span><div class="progress-bar"></div></div>
            </div>
          </div>
        </div>
      </f7-block>
      <f7-block class="no-margin-top review">
        <div class="title-row">
          <div class="wrap-title">
            <img src="static/img/user-1.png" alt="" srcset="">
            <h5 class="no-margin-vertical">Анна</h5>
          </div>
          <i class="icon icon-move-vert grey"></i>
        </div>
        <div class="wrap-date">
          <i class="icon icon-group-stars-full"></i>
          <span>22.08.23</span>
        </div>
        <div class="text">
          <p class="no-margin-vertical">Сорвала куш в Olymp Casino! Удвоила первый выигрыш, да еще и бонусы каждую неделю.</p>
        </div>
        <div class="useful">
          <span>Был ли этот отзыв полезен?</span>
          <div class="answers">
            <span>Да</span>
            <span>Нет</span>
          </div>
        </div>
      </f7-block>
      <f7-block class="review">
        <div class="title-row">
          <div class="wrap-title">
            <img src="static/img/user-2.png" alt="" srcset="">
            <h5 class="no-margin-vertical">Андрей</h5>
          </div>
          <i class="icon icon-move-vert grey"></i>
        </div>
        <div class="wrap-date">
          <i class="icon icon-group-stars-full"></i>
          <span>15.09.23</span>
        </div>
        <div class="text">
          <p class="no-margin-vertical">Попал в турнир по слотам - это был кайф! Да еще и призы достойные. Спасибо, Olymp Casino, за острые ощущения и хорошо проведенное время. Рекоменую</p>
        </div>
        <span class="super-usefull">1 человек считает этот отзыв полезным</span>
        <div class="useful">
          <span>Был ли этот отзыв полезен?</span>
          <div class="answers">
            <span class="active">Да</span>
            <span>Нет</span>
          </div>
        </div>
      </f7-block>
      <!-- <f7-block class="all-reviews">
        <span>Все отзывы</span>
      </f7-block> -->
    </f7-page-content>
  </f7-page>
</template>

<script>
  import '../css/icons.css';
  import '../css/app.scss';

  import Swiper from 'swiper';
  import 'swiper/swiper-bundle.css';

  export default {
    methods: {
      openImage($event) {
        const idx = $event.target.getAttribute('data-id') - 1;
        this.photoBrowserStandalone.activeIndex = idx;
        this.photoBrowserStandalone.open();
      },
      showMore : function (e) {
        $(e.target).hide();
        $('#info-more').show();
      },
    },
    mounted() {
      this.photoBrowserStandalone = this.$f7.photoBrowser.create({
          photos : [
              `${document.location.href}static/screensMini/i1.webp`,
              `${document.location.href}static/screensMini/i2.webp`,
              `${document.location.href}static/screensMini/i3.webp`,
              `${document.location.href}static/screensMini/i4.webp`,
              // `${document.location.href}static/screens/icon4.jpg`,
              // `${document.location.href}static/screens/icon5.png`,
              // `${document.location.href}static/screens/icon6.png`,
              // `${document.location.href}static/screens/icon7.png`,
              // `${document.location.href}static/screens/screen-1.png`,
              // `${document.location.href}static/screens/screen-1.png`,
              // `${document.location.href}static/screens/screen-1.png`,
          ],
          theme: 'dark',
          popupCloseLinkText: 'Закрыть'
      });
    },
    data: () => ({
      photoBrowserStandalone: null,
    })
  }
</script>

<style lang="scss" scoped>
  #container-screens {
    width:100%;
    height: 180px;
    overflow: hidden;

    #screens {
      height:190px; /* 40px - more place for scrollbar, is hidden under parent box */
      padding:0px 27px;
      white-space:nowrap;
      overflow-x: scroll;
      overflow-y: hide;
      -webkit-overflow-scrolling:touch;
        .screen {
          display:inline-block;

          img {
            box-shadow: -1px 2px 1px rgba(0, 0, 0, 0.06), -1px 1px 4px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
          }
        }
        .screen + .screen {
            margin-left:6px
        }
    }
  }
  .main-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 27px;

    .first-row {
      display: flex;
      padding: 19px  0px 24px;

      .logo-wrap {
        background: #fff;
        border-radius: 12.0312px;
        width: 66px;
        height: 66px;
      }
      .title-wrap {
        display: flex;
        padding: 0px 28px 0px 27px;
        align-items: flex-start;

        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          color: #000000;
        }
      }
    }
    .second-row {
      .table {
        width: 100%;

        tr {
          &:nth-child(1) {
            td {
              position: relative;

              &:nth-child(1) {
                &:after {
                  content: none;
                  width: 0;
                  height: 26px;
                  position: absolute;
                  opacity: 0.15;
                  border: 1px solid #000000;
                  right: 10%;
                  top: 40%;
                }
              }
              &:nth-child(2) {
                &:after {
                 content: none;
                  width: 0;
                  height: 26px;
                  position: absolute;
                  opacity: 0.15;
                  border: 1px solid #000000;
                  right: 10%;
                  top: 40%;
                }
              }
            }
          }
        }

        td {
          width: 33%;
        }

        .flex {
          display: flex;
        }
        .justify-center {
          justify-content: center;
        }
        .align-items {
          align-items: center;
        }

        .star-cell {
          .text {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 13px;
            color: #000000;
          }
        }
        .downloads-cell {
          .text {
            font-style: normal;
            font-weight: 500;
            font-size: 13.5px;
            line-height: 13.5px;
            color: #000000;
          }
        }
        .description-cell {
          .text {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #000000;
            opacity: 0.6;
            margin-top: 0px;
            margin-bottom: 0px;
          }
        }
      }
    }
    .third-row {
      width: 100%;
      margin: 26px 0px 16px;

      .submit {
        display: block;
        height: 36px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
        width: 100%;
        background: #00875F;
        border-radius: 4px;
      }
    }
    .fourth-row {
      display: flex;
      justify-content: space-between;
      margin: 0px 0px 31px;
      padding: 0px;
    }
  }
  .description {
    padding: 0px 27px;
    margin-top: 40px 0px 24px;

    .title-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrap-title {
        display: inline-flex;
        align-items: center;

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: #000000;
          margin-right: 25px;
        }
      }
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      opacity: 0.6;
    }
  }
  .keywords {
    padding: 0px 27px;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #000000;
      opacity: 0.6;
      padding: 6px 21px;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 34px;
    }
    span + span {
      margin-left: 8px;
    }
  }
  .reviews {
    padding: 0px 27px;

    .title-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrap-title {
        display: inline-flex;
        align-items: center;

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: #000000;
          margin-right: 25px;
        }
      }
    }
    .contaiter {
      display: flex;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 44px;

      .raiting {
        flex: 1;

        .wrap-box {
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          h5 {
            font-style: normal;
            font-weight: bold;
            font-size: 54px;
            line-height: 54px;
            letter-spacing: 0.035em;
            color: #000000;
            margin-bottom: 5px;
          }
          .people {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            color: #000000;
            opacity: 0.6;
            margin-top: 4px;
          }
        }
      }
      .details {
        flex: 2;

        .wrap-lines {
          .progress-bar {
            background: #E6E6E6;
            border-radius: 36px;
            height: 13px;
            width: 100%;
          }
          .line {
            display: flex;
            align-items: center;

            span {
              margin-right: 11px;
            }

            .value-1 {
              width: 80%;
              height: 13px;
              background-color: #00875F;
              border-radius: 36px;
            }
            .value-2 {
              width: 10%;
              height: 13px;
              background-color: #00875F;
              border-radius: 36px;
            }
            .value-3 {
              width: 6%;
              height: 13px;
              background-color: #00875F;
              border-radius: 36px;
            }
          }
        }
      }
      .raiting + .details {
        margin-left: 8px;
      }
    }
  }
  .review {
    padding: 0px 27px;

    .title-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .wrap-title {
        display: inline-flex;
        align-items: center;

        img {
          border-radius: 50%;
          margin-right: 16px;
        }

        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          color: #000000;
          margin-right: 25px;
        }
      }
    }
    .wrap-date {
      display: flex;
      align-items: center;
      margin-top: 16px;

      i {
        margin-right: 6px;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 12px;
        color: #000000;
        opacity: 0.6;
      }
    }
    .text {
      margin-top: 8px;

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        opacity: 0.6;
      }
    }
    .super-usefull {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #000000;
      opacity: 0.6;
      margin-top: 5px;
      margin-bottom: 18px;
    }
    .text + .super-usefull {
      margin-top: 5px;
      display: block;
    }
    .text + .useful {
      margin-top: 18px;
    }
    .useful {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
        opacity: 0.6;
      }

      .answers {
        .active {
          background: #E7F3EF !important;
          color: #00875F;
          opacity: 1;
          border: none;
          padding: 5px 15px;
        }
        span {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 13px;
          color: #000000;
          opacity: 0.6;
          border: thin solid rgba(0, 0, 0, 0.5);
          border-radius: 26px;
          padding: 3px 13px;
        }
      }
    }
  }
  .review + .review {
    margin-top: 50px;
  }
  .all-reviews {
    padding: 0px 27px;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 0.01em;
      color: #00875F;
    }
  }
</style>
